import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';

export const LoadingSpinner = ({
  message,
  height,
}: {
  message?: string;
  height?: string | number;
}) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        // backgroundColor: 'neutral.900',
        display: 'flex',
        flexDirection: 'column',
        height: height || '100%',
        width: '100%',
        justifyContent: 'center',
        p: 3,
        position: 'realative',
        zIndex: 2000,
      }}
    >
      <CircularProgress />
      {message && (
        <Box
          m={2}
          p={1}
          // borderRadius={1}
        >
          <Typography variant="subtitle1" color="textSecondary">
            {message}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
